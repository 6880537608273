import Rails from "rails-ujs";
import $ from "jquery";
import Turbolinks from "turbolinks";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Foundation from "foundation-sites";
import * as ActiveStorage from "activestorage";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

import "application.scss";

import "images/logo.svg";
import "images/scroll-icon.svg";
import "images/mot.png";
import "images/favicon.png";
import "images/facebook-cover.png";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Foundation.addToJquery($);

$(document).on("turbolinks:load", function() {
  $(this).foundation();

  $("[data-slick='testimonials']").slick({
    dots: false,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
